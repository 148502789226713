@import url(https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap);
* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  font-family: "Poppins", sans-serif;
  zoom: 1;
}

:root {
  --bgColor: rgb(243, 243, 243);
  --card: rgb(255, 255, 255);
  --textColor: #212121;
  --textColorOnColoredCard: white;
  --scrollBarBgColor: #d7d7d7;
  --subtitleColor: gray;
  --error: #ef3038;
  --dividerColor: rgb(221, 221, 221);
  --silverColor: #c0c0c0;
  --diamondColor: #b9f2ff;
  --goldColor: #0c7800;
  --primaryColor: #0c7800;
  --red: #0c7800;
  --yellow: #0c7800;
  --yellowAccent: white;
  --green: #0c7800;
}

body {
  background-color: rgb(243, 243, 243);
  background-color: var(--bgColor);
}

.c-red {
  color: #0c7800 !important;
  color: var(--red) !important;
}

.bg-red {
  background-color: #0c7800 !important;
  background-color: var(--red) !important;
}

.bg-yellow {
  background-color: #0c7800 !important;
  background-color: var(--yellow) !important;
}

.bg-green {
  background-color: #0c7800 !important;
  background-color: var(--green) !important;
}

.large-title {
  font-size: clamp(2.3rem, 1.595rem + 2.76vw, 3rem);
}

.headline1 {
  font-size: clamp(1.5rem, 1rem + 1.8vw, 2.5rem);
}

.large-subtitle {
  font-size: clamp(1rem, 0.5856rem + 2.21vw, 2rem);
}

.column-gap-middle {
  grid-column-gap: 0.3rem;
  -webkit-column-gap: 0.3rem;
          column-gap: 0.3rem;
}

.p-medium {
  font-size: clamp(1rem, 1.2rem, 2rem);
}

.flex-row {
  display: flex;
  flex-direction: row;
}

.flex-column {
  display: flex;
  flex-direction: column;
}

.f-ai-center {
  align-items: center;
}

.f-jc-center {
  justify-content: center;
}

.column-gap-auto {
  grid-column-gap: 1rem;
  -webkit-column-gap: 1rem;
          column-gap: 1rem;
}

.row-gap-auto {
  grid-row-gap: 1rem;
  row-gap: 1rem;
}

.screen-sides {
  display: flex;
  width: 100vw;
  height: 100h;
  background-color: rgb(243, 243, 243);
  background-color: var(--bgColor);
}

.screen-sides .left,
.screen-sides .right {
  width: 50%;
  height: 100vh;
  display: flex;
  flex-direction: column;
  grid-row-gap: 0.5rem;
  row-gap: 0.5rem;
  justify-content: center;
  padding: 4rem;
}

textarea {
  background-color: rgb(255, 255, 255);
  background-color: var(--card);
  border: none;
  width: 100%;
  height: 10rem;
  padding: 10px;
  margin: 4px 0px;
  border: 1px solid rgb(255, 255, 255);
  border: 1px solid var(--card);
  box-sizing: border-box;
  color: #212121;
  color: var(--textColor);
  outline: none;
  resize: none;
}

input,
select,
input:focus,
button,
textarea,
.button-label {
  background-color: rgb(255, 255, 255);
  background-color: var(--card);
  outline: none;
  border: none;
  width: 100%;
  height: 3rem;
  padding: 0rem 0.5rem;
  box-sizing: border-box;
  color: #212121;
  color: var(--textColor);
  border: 0.1rem solid white;
  border: 0.1rem solid var(--yellowAccent);
  font-size: small;
  text-align: start;
}

.divider {
  width: 100% !important;
  padding: 0.1rem !important;
  border-bottom: 0.1rem solid rgb(233, 233, 233) !important;
  height: 1rem !important;
}

input:focus,
textarea:focus {
  border: 0.1rem solid #0c7800;
  border: 0.1rem solid var(--green);
}

textarea {
  height: 10rem;
}

#react-quiller {
  height: 10rem !important;
  margin: 2rem 0rem;
  padding: 0;
}

input::-webkit-input-placeholder, select::-webkit-input-placeholder, textarea::-webkit-input-placeholder {
  font-size: small;
  text-align: start;
  margin: 0px;
  color: var(--text);
}

input::placeholder,
select::placeholder,
textarea::placeholder {
  font-size: small;
  text-align: start;
  margin: 0px;
  color: var(--text);
}

select,
select:focus {
  color: gray;
  color: var(--subtitleColor);
}

button,
.button-label {
  background-color: #0c7800;
  background-color: var(--primaryColor);
  width: auto;
  cursor: pointer;
  border: none;
  padding: 0rem 1rem;
  font-size: medium;
  font-weight: 500;
  color: white;
}

.button-label {
  display: flex;
  align-items: center;
  justify-content: center;
}

.input-labeled {
  display: flex;
  flex-direction: column;
  grid-row-gap: 0.5rem;
  row-gap: 0.5rem;
  margin: 0.5rem 0px;
}

.input-labeled label {
  font-size: medium;
  color: var(--text);
}

.w-expand {
  width: 100% !important;
}

a {
  text-decoration: none !important;
  color: #212121;
  color: var(--textColor);
}

.language-utils {
  display: flex;
  flex-direction: column !important;
  width: 50%;
  height: 100%;
  padding: 1rem;
  grid-row-gap: 1rem;
  row-gap: 1rem;
  overflow-x: hiden;
  overflow-y: auto;
}

.oche-simple-utils {
  display: flex;
  flex-direction: column !important;
  width: 100%;
  padding: 1rem;
}

.oche-simple-utils .options-inputs-row {
  margin-top: 0.5rem;
  background-color: rgb(228, 228, 228);
  padding: 1rem;
  border: none;
}

.oche-simple-utils .input-labeled {
  margin: 0;
}

.oche-simple-utils-inputs {
  display: flex;
  flex-direction: column;
  width: 100%;
  grid-row-gap: 0.5rem;
  row-gap: 0.5rem;
}

.center {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.oche-canevas {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: row;
  grid-column-gap: 1rem;
  -webkit-column-gap: 1rem;
          column-gap: 1rem;
  overflow: hidden;
}

.caneva-builder {
  width: 50%;
  height: 100%;
  overflow: auto;
  padding: 1rem;
}

.single-section {
  width: 100%;
  height: -webkit-fit-content;
  height: -moz-fit-content;
  height: fit-content;
  display: flex;
  flex-direction: column;
}

.s-action-main {
  display: flex;
  flex-direction: column;
  width: 100%;
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
  margin-bottom: 1rem;
}

.s-action-part {
  width: 100%;
  display: flex;
  padding: 1rem;
  align-items: center;
  justify-content: space-between;
  border-bottom: .1rem solid #0c7800;
  border-bottom: .1rem solid var(--primaryColor);
}

.caneva-builder .oche-inputs-item {
  margin: 1.5rem 0rem;
  align-items: flex-start;
  border-bottom: 0.1rem solid rgb(221, 221, 221);
  padding-bottom: 0;
}

.s-action-data {
  width: 100%;
  height: -webkit-fit-content;
  height: -moz-fit-content;
  height: fit-content;
  padding: 1rem;
  background-color: rgb(221, 221, 221);
}

.indicator-form {
  grid-column-gap: 0.5rem;
  -webkit-column-gap: 0.5rem;
          column-gap: 0.5rem;
  display: flex;
  flex-direction: column;
}

.single-section .options-reg {
  margin-top: 0.5rem;
}

.opts-single-section {
  background-color: rgb(175, 175, 175) !important;
}

#oche_report {
  width: 100%;
  display: flex !important;
  flex-direction: row;
  height: 100%;
  overflow: hidden;
}

.summary-list {
  width: 50%;
  min-width: 50%;
  height: 100%;
  padding: 1rem;
  display: flex;
  flex-direction: column;
  overflow-y: auto;
}

.summary-list .oche-inputs-item,
.summary-list .s-action-main {
  border: none;
  margin-top: 1rem;
}

.single-section {
  border: none;
}

.single-row {
  width: 100%;
  background-color: white;
  padding: 1rem;
}

.input-disable {
  pointer-events: none;
  border: 1px solid grey;
  background-color: lightgrey;
}

p, label, button{
  font-size: small !important;
}

.oche-main{
    width: 100vw;
    height: 100vh;
    display: flex;
    background-color: var(--bgColor);
    padding: 1rem;
}

.oche-main-left, .oche-main-right{
    width: 50%;
    display: flex;
    flex-direction: column;
    grid-row-gap: 1rem;
    row-gap: 1rem;
    padding: 1rem;
    height: 100%;
    justify-content: center;
    padding: 1rem 4rem;
}



.oche-main-actions{
    display: flex;
    width: 100%;
    grid-column-gap: 1rem;
    -webkit-column-gap: 1rem;
            column-gap: 1rem;
}

.oche-main-right img{
    padding: 3rem !important;
    height: 100%;
    width: 100%;
    object-fit: contain;
}

.modal-curtain{
    position: fixed;
    width: 100vw;
    height: 100vh;
    top: 0;
    bottom: 0;
    right: 0;
    left: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 1300;
    background-color: rgba(0, 0, 0, 0.50);
}

.oche-modal{
    width: 95%;
    height: 95%;
    display: flex;
    flex-direction: column;
    overflow-x: hidden;
    overflow-y: hidden;
    background-color: var(--card);
    padding: 1rem;
    align-items: flex-end;
}

.oche-modal i{
    font-size: x-larger;
    text-align: end;
    cursor: pointer;
}

.modal-child{
    width: 100%;
    height: 100%;
    min-height: 100%;
    min-width: 100%;
    overflow: hidden;
}
.oche-country{
    width: 100%;
    height: 100%;
    display: flex;
    grid-column-gap: 1rem;
    -webkit-column-gap: 1rem;
            column-gap: 1rem;
    align-items: center;
    justify-content: center;
    overflow: hidden;
}

.oche-country-editing, .oche-country-overview{
    display: flex;
    flex-direction: column;
    grid-row-gap: 1rem;
    row-gap: 1rem;
    background-color: var(--bgColor);
    padding: 1.5rem;
    height: 100%;
    width: 50%;
    overflow-y: auto;
}

.oche-country-overview{
   background-color: var(--card);
}

.oche-languages-form{
    display: flex;
    width: 100%;
    align-items: center;
    grid-column-gap: 1rem;
    -webkit-column-gap: 1rem;
            column-gap: 1rem;
}

.oche-languages-form .input-labeled{
    width: 100%;
}

.oche-languages-form form{
    margin: .5rem 0rem;
}

.oche-title{
    display: flex;
    grid-column-gap: 1rem;
    -webkit-column-gap: 1rem;
            column-gap: 1rem;
    align-items: center;
    justify-content: space-between;
}

.oche-title button{
    height: -webkit-fit-content;
    height: -moz-fit-content;
    height: fit-content;
    padding: .5rem 1rem;
    font-size: small;
}
.oche-home{
    width: 100vw;
    height: 100vh;
    background-color: var(--bgColor);
    display: flex;
    flex-direction: column;
    justify-content: center;
    grid-row-gap: 1rem;
    row-gap: 1rem;
}

.oche-home-app{
    width: 100%;
    display: flex;
    justify-content: space-between;
    grid-column-gap: 1rem;
    -webkit-column-gap: 1rem;
            column-gap: 1rem;
    padding: .5rem 1.5rem;
    background-color: var(--card);
}

.icon-button{
    font-size: xx-large;
    color: var(--primaryColor);
    cursor: pointer;
}

.oche-home-actions{
    display: flex;
    width: 100%;
    grid-column-gap: 1rem;
    -webkit-column-gap: 1rem;
            column-gap: 1rem;
    padding: 0rem 20rem;
}


.oche-home-single-home{
    background-color: var(--card);
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 3rem 1rem;
    cursor: pointer;
    border-radius: 1rem;
}

.oche-home-single-home i{
    font-size: x-large;
    margin: .2rem;
}

.dash-img{
    height: 3rem;
    min-height: 3rem;
    max-height: 3rem;
    width: 3rem;
    max-width: 3rem;
    min-width: 3rem;
}

.oche-home-save-actions{
    width: 100%;
    padding: 0rem 20rem;
    display: flex;
    flex-direction: row;
    grid-column-gap: 2rem;
    -webkit-column-gap: 2rem;
            column-gap: 2rem;
    align-items: center;
    justify-content: flex-start;
}

.oche-home-save-actions button{
    background-color: var(--bgColor);
    color: var(--primaryColor);
    padding: 0;
    margin: 0;
}



.oche-registers{
    width: 100%;
    height: 100%;
    display: flex;
    overflow: hidden;
    grid-column-gap: 1rem;
    -webkit-column-gap: 1rem;
            column-gap: 1rem;
}


.oche-registers-list, .oche-registers-form{
    width: 50%;
    height: 100%;
    overflow-y: auto;
    background: var(--bgColor);
    padding: 1.5rem;
    display: flex;
    flex-direction: column;
    grid-row-gap: 1rem;
    row-gap: 1rem;
}

.oche-registers-form button{
    width: 100%;
    height: -webkit-fit-content;
    height: -moz-fit-content;
    height: fit-content;
    padding: .5rem;
}

.single-register{
    width: 100%;
    background-color: var(--card);
    padding: 1rem;
    display: flex;
    flex-direction: column;
    cursor: pointer;
}

.bg-green{
    background-color: green;
}

.bg-orange{
    background-color: orange;
}

#p-bg{
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
    color: white;
    padding: .5rem;
    font-size: small;
    margin-bottom: .5rem;
}

.single-register .flex-row{
    align-items: center;
    justify-content: space-between;
}
.oche-form {
    width: 100%;
    display: flex;
    overflow: hidden;
    height: 100%;
}

.oche-inputs {
    width: 80%;
    min-width: 80%;
    height: 100%;
    display: flex;
    padding: 1.5rem;
    flex-direction: column;
    grid-row-gap: .5rem;
    row-gap: .5rem;
    overflow-y: auto;
}

.oche-inputs-title {
    width: 100%;
    height: -webkit-fit-content;
    height: -moz-fit-content;
    height: fit-content;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.oche-inputs-title button {
    height: -webkit-fit-content;
    height: -moz-fit-content;
    height: fit-content;
    padding: .5rem 1.5rem;
}

.single-oche-input {
    width: 100%;
    display: flex;
    flex-direction: column;
    background-color: rgb(198, 198, 198);
    padding: 0 1rem;
    border: .1rem solid rgb(224, 224, 224);
    grid-row-gap: .5rem;
    row-gap: .5rem;
    padding-bottom: 1rem;
}

.oche-inputs-item {
    display: flex;
    width: 100%;
    align-items: flex-start;
    grid-column-gap: 1rem;
    -webkit-column-gap: 1rem;
            column-gap: 1rem;
    border-bottom: .1rem solid gray;
    padding-bottom: 1rem;
}


.options-inputs-row {
    display: flex;
    width: 100%;
    align-items: flex-start;
    grid-column-gap: 1rem;
    -webkit-column-gap: 1rem;
            column-gap: 1rem;
    margin-top: 1rem;
    border-top: .1rem solid gray;
    padding-top: .5rem;
}

.options-inputs-row .opts-single-form {
    width: 100%;
    padding: 1rem;
    background-color: gray;
}

.prov-modal {
    background-color: rgb(227, 227, 227) !important;
}

.munis-div {
    width: 100%;
}

.for-inputs {
    width: 100%;
    margin-top: .5rem;
}
.for-inputs .oche-inputs-item{
    margin-top: 1rem;
}

.for-inputs button {
    font-size: smaller;
    padding: .5rem 1rem;
}

.for-inputs .oche-inputs-title .p-medium {
    font-size: medium;
}


.oche-languages{
    width: 100%;
    display: flex;
    height: 100%;
    grid-column-gap: 1rem;
    -webkit-column-gap: 1rem;
            column-gap: 1rem;
    overflow: hidden;
}

.oche-languages-dic, .json-displayer{
    width: 50%;
    height: 100%;
    display: flex;
    flex-direction: column;
    grid-row-gap: 1rem;
    row-gap: 1rem;
    overflow-y: auto;
    overflow-x: hidden;
    background-color: var(--bgColor);
    padding: 1rem;
}

.new-key-form{
    background-color:var(--bgColor);
    padding: 1rem;
}

.new-key-form button{
    width: 100%;
    padding: .5rem 1rem;
}

.button-loading{
    background-color: gray !important;
}
.oche-dhis2 {
    width: 100%;
    height: 100%;
    display: flex;
    overflow: hidden;
    background-color: var(--bgColor) !important;
    grid-column-gap: 1rem;
    -webkit-column-gap: 1rem;
            column-gap: 1rem;
    padding: 1rem;
}


.oche_dataSets {
    width: 50%;
    min-width: 50%;
    height: 100%;
    overflow-y: auto;
    display: flex;
    flex-direction: column;
    grid-row-gap: 1rem;
    row-gap: 1rem;
    padding: .5rem;
}


.oche_dataSetsElements {
    width: 100%;
    min-width: 100%;
    min-height: 100%;
    height: 100%;
    overflow-y: auto;
    display: flex;
    flex-direction: column;
    grid-row-gap: 1rem;
    row-gap: 1rem;
    background-color: var(--card) !important;
    padding: 1rem;
}

.row-in-put {
    display: flex;
    width: 100%;
    grid-column-gap: 1rem;
    -webkit-column-gap: 1rem;
            column-gap: 1rem;
    justify-content: space-between;
    align-items: center;
}

.row-in-put button {
    font-size: small;
    padding: .5rem 1rem;
}

.o-d-header {
    width: 100%;
    display: flex;
    grid-column-gap: 1rem;
    -webkit-column-gap: 1rem;
            column-gap: 1rem;
    align-items: center;
    justify-content: space-between;
}

.s-dataset {
    width: 100%;
    background-color: var(--card);
    padding: 1rem;
    display: flex;
    grid-column-gap: .5rem;
    -webkit-column-gap: .5rem;
            column-gap: .5rem;
    align-items: flex-start;
}

.s-dataset-list {
    height: 100%;
    overflow: auto;
    padding: 0 1rem 0 0;
    display: flex;
    flex-direction: column;
    grid-row-gap: .7rem;
    row-gap: .7rem;
}

.s-dataset span {
    height: 1rem;
    width: 1rem;
    min-width: 1rem;
    min-height: 1rem;
    background-color: var(--bgColor);
    border-radius: 100%;
    cursor: pointer;
}

.s-d-l-active {
    background-color: rgb(0, 132, 255) !important;
}


.s-d-body {
    width: 100%;
    display: flex;
    flex-direction: column;
    grid-row-gap: .5rem;
    row-gap: .5rem;
}

.s-dataset p {
    white-space: normal !important;
}
.oche-dhis2-datasets {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: row;
  background-color: var(--bgColor);
}

.o-d-d {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  grid-row-gap: 1rem;
  row-gap: 1rem;
  overflow-y: auto;
  overflow-y: hidden;
  padding: 1rem;
}

.o-d-d-v-d {
  max-width: 30% !important;
}

.s-datasetb {
  width: 100%;
  background-color: var(--card);
  padding: 1rem;
  display: flex;
  grid-column-gap: 0.5rem;
  -webkit-column-gap: 0.5rem;
          column-gap: 0.5rem;
  align-items: flex-start;
  cursor: pointer;
}

.s-dataset-list {
  height: 100%;
  overflow: auto;
  padding: 0 1rem 0 0;
  display: flex;
  flex-direction: column;
  grid-row-gap: 0.7rem;
  row-gap: 0.7rem;
}

.s-d-l-active {
  background-color: rgb(0, 132, 255) !important;
}

.s-d-body {
  width: 100%;
  display: flex;
  flex-direction: column;
  grid-row-gap: 0.5rem;
  row-gap: 0.5rem;
}

.s-dataset p {
  white-space: normal !important;
}

.categoryCombo {
  width: 100%;
  display: flex;
  grid-column-gap: 1rem;
  -webkit-column-gap: 1rem;
          column-gap: 1rem;
  align-items: flex-start;
  justify-content: space-between;
  padding: 1rem;
}

.categoryCombo .s-dataset-list {
  width: 100% !important;
}

.center-o-o-d {
  height: 100%;
  width: 100%;
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: var(--card);
}

.categoryCombo-left {
  width: 40%;
  display: flex;
  flex-direction: column;
  display: flex;
  grid-row-gap: 0.5rem;
  row-gap: 0.5rem;
}

.categoryComboConfig {
  -webkit-user-select: none;
  user-select: none;
  display: flex;
  flex-direction: column;
  grid-row-gap: 1rem;
  row-gap: 1rem;
}

.aggregationconditions,
.aggregationconfig {
  display: flex;
  flex-direction: column;
  grid-row-gap: 0rem;
  row-gap: 0rem;
  width: 100%;
}

.aggregationconditions .options-inputs-row {
  border: none;
}

.aggregationconditions .input-labeled {
  margin: 0;
}

.type-form {
  width: 100%;
  display: flex;
  flex-direction: column;
  grid-row-gap: 0.5rem;
  row-gap: 0.5rem;
  padding: 1rem 0rem;
}

.aggregation-form {
  width: 100%;
  display: flex;
  flex-direction: column;
  grid-row-gap: 0.5rem;
  row-gap: 0.5rem;
  padding: 0.5rem 0rem;
}

.lookup-div {
  display: flex;
  flex-direction: column;
  grid-row-gap: 0.5rem;
  row-gap: 0.5rem;
}

