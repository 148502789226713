.oche-country{
    width: 100%;
    height: 100%;
    display: flex;
    column-gap: 1rem;
    align-items: center;
    justify-content: center;
    overflow: hidden;
}

.oche-country-editing, .oche-country-overview{
    display: flex;
    flex-direction: column;
    row-gap: 1rem;
    background-color: var(--bgColor);
    padding: 1.5rem;
    height: 100%;
    width: 50%;
    overflow-y: auto;
}

.oche-country-overview{
   background-color: var(--card);
}

.oche-languages-form{
    display: flex;
    width: 100%;
    align-items: center;
    column-gap: 1rem;
}

.oche-languages-form .input-labeled{
    width: 100%;
}

.oche-languages-form form{
    margin: .5rem 0rem;
}

.oche-title{
    display: flex;
    column-gap: 1rem;
    align-items: center;
    justify-content: space-between;
}

.oche-title button{
    height: fit-content;
    padding: .5rem 1rem;
    font-size: small;
}