.oche-dhis2-datasets {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: row;
  background-color: var(--bgColor);
}

.o-d-d {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  row-gap: 1rem;
  overflow-y: auto;
  overflow-y: hidden;
  padding: 1rem;
}

.o-d-d-v-d {
  max-width: 30% !important;
}

.s-datasetb {
  width: 100%;
  background-color: var(--card);
  padding: 1rem;
  display: flex;
  column-gap: 0.5rem;
  align-items: flex-start;
  cursor: pointer;
}

.s-dataset-list {
  height: 100%;
  overflow: auto;
  padding: 0 1rem 0 0;
  display: flex;
  flex-direction: column;
  row-gap: 0.7rem;
}

.s-d-l-active {
  background-color: rgb(0, 132, 255) !important;
}

.s-d-body {
  width: 100%;
  display: flex;
  flex-direction: column;
  row-gap: 0.5rem;
}

.s-dataset p {
  white-space: normal !important;
}

.categoryCombo {
  width: 100%;
  display: flex;
  column-gap: 1rem;
  align-items: flex-start;
  justify-content: space-between;
  padding: 1rem;
}

.categoryCombo .s-dataset-list {
  width: 100% !important;
}

.center-o-o-d {
  height: 100%;
  width: 100%;
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: var(--card);
}

.categoryCombo-left {
  width: 40%;
  display: flex;
  flex-direction: column;
  display: flex;
  row-gap: 0.5rem;
}

.categoryComboConfig {
  -webkit-user-select: none;
  user-select: none;
  display: flex;
  flex-direction: column;
  row-gap: 1rem;
}

.aggregationconditions,
.aggregationconfig {
  display: flex;
  flex-direction: column;
  row-gap: 0rem;
  width: 100%;
}

.aggregationconditions .options-inputs-row {
  border: none;
}

.aggregationconditions .input-labeled {
  margin: 0;
}

.type-form {
  width: 100%;
  display: flex;
  flex-direction: column;
  row-gap: 0.5rem;
  padding: 1rem 0rem;
}

.aggregation-form {
  width: 100%;
  display: flex;
  flex-direction: column;
  row-gap: 0.5rem;
  padding: 0.5rem 0rem;
}

.lookup-div {
  display: flex;
  flex-direction: column;
  row-gap: 0.5rem;
}
