.oche-dhis2 {
    width: 100%;
    height: 100%;
    display: flex;
    overflow: hidden;
    background-color: var(--bgColor) !important;
    column-gap: 1rem;
    padding: 1rem;
}


.oche_dataSets {
    width: 50%;
    min-width: 50%;
    height: 100%;
    overflow-y: auto;
    display: flex;
    flex-direction: column;
    row-gap: 1rem;
    padding: .5rem;
}


.oche_dataSetsElements {
    width: 100%;
    min-width: 100%;
    min-height: 100%;
    height: 100%;
    overflow-y: auto;
    display: flex;
    flex-direction: column;
    row-gap: 1rem;
    background-color: var(--card) !important;
    padding: 1rem;
}

.row-in-put {
    display: flex;
    width: 100%;
    column-gap: 1rem;
    justify-content: space-between;
    align-items: center;
}

.row-in-put button {
    font-size: small;
    padding: .5rem 1rem;
}

.o-d-header {
    width: 100%;
    display: flex;
    column-gap: 1rem;
    align-items: center;
    justify-content: space-between;
}

.s-dataset {
    width: 100%;
    background-color: var(--card);
    padding: 1rem;
    display: flex;
    column-gap: .5rem;
    align-items: flex-start;
}

.s-dataset-list {
    height: 100%;
    overflow: auto;
    padding: 0 1rem 0 0;
    display: flex;
    flex-direction: column;
    row-gap: .7rem;
}

.s-dataset span {
    height: 1rem;
    width: 1rem;
    min-width: 1rem;
    min-height: 1rem;
    background-color: var(--bgColor);
    border-radius: 100%;
    cursor: pointer;
}

.s-d-l-active {
    background-color: rgb(0, 132, 255) !important;
}


.s-d-body {
    width: 100%;
    display: flex;
    flex-direction: column;
    row-gap: .5rem;
}

.s-dataset p {
    white-space: normal !important;
}