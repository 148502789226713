@import url("https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap");

* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  font-family: "Poppins", sans-serif;
  zoom: 1;
}

:root {
  --bgColor: rgb(243, 243, 243);
  --card: rgb(255, 255, 255);
  --textColor: #212121;
  --textColorOnColoredCard: white;
  --scrollBarBgColor: #d7d7d7;
  --subtitleColor: gray;
  --error: #ef3038;
  --dividerColor: rgb(221, 221, 221);
  --silverColor: #c0c0c0;
  --diamondColor: #b9f2ff;
  --goldColor: #0c7800;
  --primaryColor: #0c7800;
  --red: #0c7800;
  --yellow: #0c7800;
  --yellowAccent: white;
  --green: #0c7800;
}

body {
  background-color: var(--bgColor);
}

.c-red {
  color: var(--red) !important;
}

.bg-red {
  background-color: var(--red) !important;
}

.bg-yellow {
  background-color: var(--yellow) !important;
}

.bg-green {
  background-color: var(--green) !important;
}

.large-title {
  font-size: clamp(2.3rem, 1.595rem + 2.76vw, 3rem);
}

.headline1 {
  font-size: clamp(1.5rem, 1rem + 1.8vw, 2.5rem);
}

.large-subtitle {
  font-size: clamp(1rem, 0.5856rem + 2.21vw, 2rem);
}

.column-gap-middle {
  column-gap: 0.3rem;
}

.p-medium {
  font-size: clamp(1rem, 1.2rem, 2rem);
}

.flex-row {
  display: flex;
  flex-direction: row;
}

.flex-column {
  display: flex;
  flex-direction: column;
}

.f-ai-center {
  align-items: center;
}

.f-jc-center {
  justify-content: center;
}

.column-gap-auto {
  column-gap: 1rem;
}

.row-gap-auto {
  row-gap: 1rem;
}

.screen-sides {
  display: flex;
  width: 100vw;
  height: 100h;
  background-color: var(--bgColor);
}

.screen-sides .left,
.screen-sides .right {
  width: 50%;
  height: 100vh;
  display: flex;
  flex-direction: column;
  row-gap: 0.5rem;
  justify-content: center;
  padding: 4rem;
}

textarea {
  background-color: var(--card);
  border: none;
  width: 100%;
  height: 10rem;
  padding: 10px;
  margin: 4px 0px;
  border: 1px solid var(--card);
  box-sizing: border-box;
  color: var(--textColor);
  outline: none;
  resize: none;
}

input,
select,
input:focus,
button,
textarea,
.button-label {
  background-color: var(--card);
  outline: none;
  border: none;
  width: 100%;
  height: 3rem;
  padding: 0rem 0.5rem;
  box-sizing: border-box;
  color: var(--textColor);
  border: 0.1rem solid var(--yellowAccent);
  font-size: small;
  text-align: start;
}

.divider {
  width: 100% !important;
  padding: 0.1rem !important;
  border-bottom: 0.1rem solid rgb(233, 233, 233) !important;
  height: 1rem !important;
}

input:focus,
textarea:focus {
  border: 0.1rem solid var(--green);
}

textarea {
  height: 10rem;
}

#react-quiller {
  height: 10rem !important;
  margin: 2rem 0rem;
  padding: 0;
}

input::placeholder,
select::placeholder,
textarea::placeholder {
  font-size: small;
  text-align: start;
  margin: 0px;
  color: var(--text);
}

select,
select:focus {
  color: var(--subtitleColor);
}

button,
.button-label {
  background-color: var(--primaryColor);
  width: auto;
  cursor: pointer;
  border: none;
  padding: 0rem 1rem;
  font-size: medium;
  font-weight: 500;
  color: white;
}

.button-label {
  display: flex;
  align-items: center;
  justify-content: center;
}

.input-labeled {
  display: flex;
  flex-direction: column;
  row-gap: 0.5rem;
  margin: 0.5rem 0px;
}

.input-labeled label {
  font-size: medium;
  color: var(--text);
}

.w-expand {
  width: 100% !important;
}

a {
  text-decoration: none !important;
  color: var(--textColor);
}

.language-utils {
  display: flex;
  flex-direction: column !important;
  width: 50%;
  height: 100%;
  padding: 1rem;
  row-gap: 1rem;
  overflow-x: hiden;
  overflow-y: auto;
}

.oche-simple-utils {
  display: flex;
  flex-direction: column !important;
  width: 100%;
  padding: 1rem;
}

.oche-simple-utils .options-inputs-row {
  margin-top: 0.5rem;
  background-color: rgb(228, 228, 228);
  padding: 1rem;
  border: none;
}

.oche-simple-utils .input-labeled {
  margin: 0;
}

.oche-simple-utils-inputs {
  display: flex;
  flex-direction: column;
  width: 100%;
  row-gap: 0.5rem;
}

.center {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.oche-canevas {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: row;
  column-gap: 1rem;
  overflow: hidden;
}

.caneva-builder {
  width: 50%;
  height: 100%;
  overflow: auto;
  padding: 1rem;
}

.single-section {
  width: 100%;
  height: fit-content;
  display: flex;
  flex-direction: column;
}

.s-action-main {
  display: flex;
  flex-direction: column;
  width: 100%;
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
  margin-bottom: 1rem;
}

.s-action-part {
  width: 100%;
  display: flex;
  padding: 1rem;
  align-items: center;
  justify-content: space-between;
  border-bottom: .1rem solid var(--primaryColor);
}

.caneva-builder .oche-inputs-item {
  margin: 1.5rem 0rem;
  align-items: flex-start;
  border-bottom: 0.1rem solid rgb(221, 221, 221);
  padding-bottom: 0;
}

.s-action-data {
  width: 100%;
  height: fit-content;
  padding: 1rem;
  background-color: rgb(221, 221, 221);
}

.indicator-form {
  column-gap: 0.5rem;
  display: flex;
  flex-direction: column;
}

.single-section .options-reg {
  margin-top: 0.5rem;
}

.opts-single-section {
  background-color: rgb(175, 175, 175) !important;
}

#oche_report {
  width: 100%;
  display: flex !important;
  flex-direction: row;
  height: 100%;
  overflow: hidden;
}

.summary-list {
  width: 50%;
  min-width: 50%;
  height: 100%;
  padding: 1rem;
  display: flex;
  flex-direction: column;
  overflow-y: auto;
}

.summary-list .oche-inputs-item,
.summary-list .s-action-main {
  border: none;
  margin-top: 1rem;
}

.single-section {
  border: none;
}

.single-row {
  width: 100%;
  background-color: white;
  padding: 1rem;
}

.input-disable {
  pointer-events: none;
  border: 1px solid grey;
  background-color: lightgrey;
}

p, label, button{
  font-size: small !important;
}
